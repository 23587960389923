/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    code: "code",
    strong: "strong",
    ul: "ul",
    li: "li",
    h3: "h3",
    pre: "pre",
    blockquote: "blockquote",
    ol: "ol"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "dice-combinations--cses--problem",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dice-combinations--cses--problem",
    "aria-label": "dice combinations  cses  problem permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Dice Combinations ( CSES ) Problem"), "\n", React.createElement(_components.p, null, "and Dynamic Programming Solution using tabulation and memoization in Rust Language."), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "The Dice Combinations is a very similar problem to to ", React.createElement(_components.a, {
    href: "/dynamic-programming/coin-change/"
  }, "Coin Change Problem"), " , except that in this problem, the order of the selection also matters."), "\n", React.createElement(_components.p, null, "For example, in this problem, ", React.createElement(_components.code, null, "1,2"), " and ", React.createElement(_components.code, null, "2,1"), " is counted as different unlike Coin change problem."), "\n", React.createElement(_components.p, null, "So, in this problem, we have to find number of ways we can form a sum rolling dice any number of times."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "For Example :"), " If sum is 3, there are 4 ways to make it"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "1+1+1"), "\n", React.createElement(_components.li, null, "1+2"), "\n", React.createElement(_components.li, null, "2+1"), "\n", React.createElement(_components.li, null, "3"), "\n"), "\n", React.createElement(_components.p, null, "Hence, the output should be ", React.createElement(_components.strong, null, "4"), "."), "\n", React.createElement(_components.p, null, "This problem has been taken from our beloved ", React.createElement(_components.a, {
    href: "https://cses.fi/problemset/task/1633"
  }, "CSES DP problem set. See Dice Combinations")), "\n", React.createElement(_components.h2, {
    id: "recursive-solution",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#recursive-solution",
    "aria-label": "recursive solution permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Recursive Solution"), "\n", React.createElement(_components.p, null, "In the Recursive Solution to this problem, we have to make an observation."), "\n", React.createElement(_components.p, null, "If we fix the first number that comes on the dice, we can calculate the number of ways by subtracting it from n and calculating recursively."), "\n", React.createElement(_components.p, null, "For example, if we have to find the number of ways for ", React.createElement(_components.strong, null, "n = 7"), ", then we can fix first roll as 1, 2, 3, 4, 5 and 6 and then calculate the answer recursively."), "\n", React.createElement(_components.p, null, "To Summarize approach,"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Base Case :"), " If the sum is less than or equal to 6, return corresponding value ( we already manually compute and store those values in an array )"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Recursive Case :"), " Return the sum of recursively calculating answer for n-1, n-2, n-3, n-4, n-5 and n-6."), "\n"), "\n", React.createElement(_components.h3, {
    id: "function",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function",
    "aria-label": "function permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function"), "\n", React.createElement(_components.p, null, "Here is the function using above algorithm"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn dice_combinations(sum :usize) -> usize{\n    // We can make 0 in 1 and only 1 way,\n    // that is by not rolling dice anymore :D\n    static UPTO_6 : [usize; 7] = [1, 1, 2, 4, 8, 16, 32];\n\n    // Base Case : If element is less than or equal to 6,\n    // return corresponding value\n    if sum <= 6 { return UPTO_6[sum]; }\n\n    // Recursive Case : Else, return sum of all possible sums,\n    // That is, by throwing 1, 2, 3, 4, 5 and 6.\n    return (\n        dice_combinations(sum-1)+dice_combinations(sum-2)+\n        dice_combinations(sum-3)+dice_combinations(sum-4)+\n        dice_combinations(sum-5)+dice_combinations(sum-6)\n    ) % 1_000_000_007;\n}\n")), "\n", React.createElement(_components.p, null, "With driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn dice_combinations(sum :usize) -> usize{\n    // We can make 0 in 1 and only 1 way,\n    // that is by not rolling dice anymore :D\n    static UPTO_6 : [usize; 7] = [1, 1, 2, 4, 8, 16, 32];\n\n    // Base Case : If element is less than or equal to 6,\n    // return corresponding value\n    if sum <= 6 { return UPTO_6[sum]; }\n\n    // Recursive Case : Else, return sum of all possible sums,\n    // That is, by throwing 1, 2, 3, 4, 5 and 6.\n    return (\n        dice_combinations(sum-1)+dice_combinations(sum-2)+\n        dice_combinations(sum-3)+dice_combinations(sum-4)+\n        dice_combinations(sum-5)+dice_combinations(sum-6)\n    ) % 1_000_000_007;\n}\n\n// Driver Code\nuse std::io;\n\nfn take_int() -> usize {\n    let mut input = String::new();\n    io::stdin().read_line(&mut input).unwrap();\n    return input.trim().parse().unwrap();\n}\n\nfn main() {\n    let sum = take_int();\n    println!(\"{}\", dice_combinations(sum));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "7"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "63"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( 2", React.createElement("sup", null, "n"), " )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( n )")), "\n", React.createElement(_components.p, null, "( Space complexity includes recursive stack space )"), "\n", React.createElement(_components.h2, {
    id: "overlapping-sub-problems",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overlapping-sub-problems",
    "aria-label": "overlapping sub problems permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Overlapping Sub-problems"), "\n", React.createElement(_components.p, null, "If we have a look carefully on recursive approach, we computed multiple results many times."), "\n", React.createElement(_components.p, null, "For example, if we have n = 30, then the result for n=15 is calculated thousands of times, and takes thousands of iterations each time to calculate the result."), "\n", React.createElement(_components.p, null, "These are called overlapping sub-problems, because it is a sub-problem of actual problem and is overlapping in multiple recursions."), "\n", React.createElement(_components.p, null, "To prevent this, we can store the output and each result will be calculated only once."), "\n", React.createElement(_components.p, null, "As the result is only dependent on sum, we can simply store the values in an array or a vector, at the index of sum itself."), "\n", React.createElement(_components.p, null, "For example, the result for sum = 15 will be stored at index 15."), "\n", React.createElement(_components.p, null, "This is called ", React.createElement(_components.strong, null, "memoization"), " or Top-down Dynamic Programming."), "\n", React.createElement(_components.h2, {
    id: "memoization--top-down-dp--method",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#memoization--top-down-dp--method",
    "aria-label": "memoization  top down dp  method permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Memoization ( Top-down DP ) Method"), "\n", React.createElement(_components.p, null, "In memoization method, we simply take a DP array, and store the computed result."), "\n", React.createElement(_components.h3, {
    id: "algorithm",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#algorithm",
    "aria-label": "algorithm permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Algorithm"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Base Case :"), " If the stored value for given sum in DP array is not ", React.createElement(_components.code, null, "None"), ", we return the value."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Recursive Case :"), " Else, compute the result using recursion and store it at the index of sum."), "\n"), "\n", React.createElement(_components.h3, {
    id: "function-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function-1",
    "aria-label": "function 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function"), "\n", React.createElement(_components.p, null, "Here is the function using above algorithm"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn dice_combinations(sum :usize, dp:&mut Vec<Option<usize>>) -> usize{\n\n    // Base Case : If result is already stored, return it\n    if dp[sum].is_some() { return dp[sum].unwrap(); }\n\n    // Recursive Case : Else, compute and return sum of all possible sums\n    // That is, by throwing 1, 2, 3, 4, 5 and 6 first.\n\n    dp[sum] = Option::from( (\n        dice_combinations(sum-1, dp)+dice_combinations(sum-2, dp)+\n        dice_combinations(sum-3, dp)+dice_combinations(sum-4, dp)+\n        dice_combinations(sum-5, dp)+dice_combinations(sum-6, dp)\n    ) % 1_000_000_007);\n\n    return dp[sum].unwrap();\n}\n")), "\n", React.createElement(_components.p, null, "With driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "\nfn dice_combinations(sum :usize, dp:&mut Vec<Option<usize>>) -> usize{\n\n    // Base Case : If result is already stored, return it\n    if dp[sum].is_some() { return dp[sum].unwrap(); }\n\n    // Recursive Case : Else, compute and return sum of all possible sums\n    // That is, by throwing 1, 2, 3, 4, 5 and 6 first.\n\n    dp[sum] = Option::from( (\n        dice_combinations(sum-1, dp)+dice_combinations(sum-2, dp)+\n        dice_combinations(sum-3, dp)+dice_combinations(sum-4, dp)+\n        dice_combinations(sum-5, dp)+dice_combinations(sum-6, dp)\n    ) % 1_000_000_007);\n\n    return dp[sum].unwrap();\n}\n\n// Driver Code\nuse std::io;\nuse std::cmp::max;\n\nfn take_int() -> usize {\n    let mut input = String::new();\n    io::stdin().read_line(&mut input).unwrap();\n    return input.trim().parse().unwrap();\n}\n\nfn main() {\n    let sum = take_int();\n\n    // Handles the case when sum < 6 using max\n    let mut dp = vec![None; max(sum+1, 7)];\n\n    // Set values for sum upto 6\n    dp[0] = Option::from(1); dp[1] = Option::from(1);\n    dp[2] = Option::from(2); dp[3] = Option::from(4);\n    dp[4] = Option::from(8); dp[5] = Option::from(16);\n    dp[6] = Option::from(32);\n\n    println!(\"{}\", dice_combinations(sum, &mut dp));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "7"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "63"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( n )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( n )")), "\n", React.createElement(_components.h2, {
    id: "tabulation---bottom-up-dp---method",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tabulation---bottom-up-dp---method",
    "aria-label": "tabulation   bottom up dp   method permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tabulation  ( Bottom-up DP )  Method"), "\n", React.createElement(_components.p, null, "Although time and space complexities of tabulation as well as memoization method are same, tabulation is much more ", React.createElement(_components.strong, null, "efficient"), " as there are a lot of expensive recursive calls in memoization."), "\n", React.createElement(_components.p, null, "In tabulation method, we make the array, and fill it first on the basis of base condition, and then on the basis of previously computed variables."), "\n", React.createElement(_components.h3, {
    id: "algorithm-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#algorithm-1",
    "aria-label": "algorithm 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Algorithm"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Firstly, make an array and store the results for sum upto 6."), "\n", React.createElement(_components.li, null, "Now, for each subsequent sum from 7 to n, set array[index] as sum of previous 6 elements."), "\n", React.createElement(_components.li, null, "Return the value of the array[n]"), "\n"), "\n", React.createElement(_components.h3, {
    id: "function-2",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function-2",
    "aria-label": "function 2 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function"), "\n", React.createElement(_components.p, null, "Here is the function using above algorithm"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn dice_combinations(sum:usize) -> usize{\n    // Handles the case when sum < 6 using max\n    let mut dp = vec![0; max(sum+1, 7)];\n\n    // Set values for sum upto 6\n    dp[0] = 1; dp[1] = 1; dp[2] = 2;\n    dp[3] = 4; dp[4] = 8;\n    dp[5] = 16; dp[6] = 32;\n\n    // For each value from 7 to sum,\n    // set dp[sum] = sum of previous 6 elements\n\n    for n in 7..sum+1 {\n        dp[n] = (dp[n-1]+dp[n-2]+dp[n-3]+\n            dp[n-4]+dp[n-5]+dp[n-6]) %1_000_000_007;\n    }\n\n    return dp[sum];\n}\n")), "\n", React.createElement(_components.p, null, "With Driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "\nfn dice_combinations(sum:usize) -> usize{\n    // Handles the case when sum < 6 using max\n    let mut dp = vec![0; max(sum+1, 7)];\n\n    // Set values for sum upto 6\n    dp[0] = 1; dp[1] = 1; dp[2] = 2;\n    dp[3] = 4; dp[4] = 8;\n    dp[5] = 16; dp[6] = 32;\n\n    // For each value from 7 to sum,\n    // set dp[sum] = sum of previous 6 elements\n\n    for n in 7..sum+1 {\n        dp[n] = (dp[n-1]+dp[n-2]+dp[n-3]+\n            dp[n-4]+dp[n-5]+dp[n-6]) %1_000_000_007;\n    }\n\n    return dp[sum];\n}\n\n// Driver Code\nuse std::io;\nuse std::cmp::max;\n\nfn take_int() -> usize {\n    let mut input = String::new();\n    io::stdin().read_line(&mut input).unwrap();\n    return input.trim().parse().unwrap();\n}\n\nfn main() {\n    let sum = take_int();\n\n    println!(\"{}\", dice_combinations(sum));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "7"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "63"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( n )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( n )")), "\n", React.createElement(_components.h2, {
    id: "space-optimized-tabulation-method",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#space-optimized-tabulation-method",
    "aria-label": "space optimized tabulation method permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Space Optimized Tabulation Method"), "\n", React.createElement(_components.p, null, "If we look tabulation method carefully, we can easily see that only previous 6 values are used to calculate the next value."), "\n", React.createElement(_components.p, null, "Hence, we don't have to store all the values upto n, and store only previous 6 values or 7 values, and calculate the result."), "\n", React.createElement(_components.h3, {
    id: "function-3",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function-3",
    "aria-label": "function 3 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function"), "\n", React.createElement(_components.p, null, "Here is the function using space optimization of tabulation method."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn dice_combinations(sum:usize) -> usize{\n    // Handles the case when sum < 6 using max\n    let mut dp = vec![0; 7];\n\n    // Set values for sum upto 6\n    dp[0] = 1; dp[1] = 1; dp[2] = 2;\n    dp[3] = 4; dp[4] = 8;\n    dp[5] = 16; dp[6] = 32;\n\n    // if sum is less than 7, return it\n    if sum < 7 {return dp[sum];}\n\n\n    // Now, we first left rotate the array\n    // Because we have already stored the value for n = 6\n    // Hence, for n=7, first left rotate,\n    // then find and store the value in dp array\n    for _ in 7..sum+1 {\n        // Left rotate the matrix\n        for j in 0..6 {\n            dp[j] = dp[j+1];\n        }\n\n        // Find and set sum as the last element of array\n        dp[6] = (dp[0]+dp[1]+dp[2]+dp[3]+dp[4]+dp[5])%1000000007;\n    }\n\n    return dp[6];\n}\n")), "\n", React.createElement(_components.p, null, "Use the same driver code except removing the dp matrix input."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "7"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "63"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( n )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "In Dice Combination Problem, we have to find number of ways we can form a sum by rolling dice any number of times."), "\n", React.createElement(_components.p, null, "In this article, we saw how to solve the dice combinations problem, first using recursion and then using Dynamic Programming, memoization as well as tabulation method, and latter the space optimized tabulation method in Rust Language."), "\n", React.createElement(_components.p, null, "Here is the optimized function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn dice_combinations(sum:usize) -> usize{\n    let mut dp = vec![0; 7];\n    dp[0] = 1; dp[1] = 1; dp[2] = 2;\n    dp[3] = 4; dp[4] = 8;\n    dp[5] = 16; dp[6] = 32;\n    if sum < 7 {return dp[sum];}\n    for _ in 7..sum+1 { for j in 0..6 { dp[j] = dp[j+1]; }\n        dp[6] = (dp[0]+dp[1]+dp[2]+dp[3]+dp[4]+dp[5])%1000000007; }\n    return dp[6];\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Dice Combinations CSES - DP - Rust Programming",
    description: "Find number of ways to form the sum by rolling dice. We will see recursive, memoization, tabulation and space optimized DP solution in Rust Language.",
    img: "https://rustp.org/Static_Images_DND/Social/Dice_Cobinations.png"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
